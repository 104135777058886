<template>
  <v-card flat>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      :loading="loading"
      :loading-text="$t('loading')"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr
            v-for="(item, i) in items"
            :key="i"
            @click="$emit('rowClick', item)"
            :class="
              $vuetify.breakpoint.xsOnly
                ? ' v-data-table__mobile-table-row'
                : ''
            "
          >
            <td
              v-for="header in headers.filter((f) => f.value != 'actions')"
              :key="header.value"
              :class="
                $vuetify.breakpoint.xsOnly
                  ? 'v-data-table__mobile-row'
                  : 'text-start'
              "
            >
              <span class="hidden-sm-and-up">
                {{ header.text }}
              </span>
              <span v-if="header.type == 'select'">
                <v-chip
                  v-if="header.chip"
                  :color="getSelectcolor(item, header)"
                >
                  {{ getSelectText(item[header.value], header) }}
                </v-chip>
                <span v-else>
                  {{ getSelectText(item[header.value], header) }}
                </span>
              </span>

              <div v-else-if="header.type == 'multiselect'" class="pa-2">
                <v-chip
                  class="ml-1 mt-1"
                  v-for="(guid, ind) in item[header.value]"
                  :key="ind"
                >
                  {{ getSelectText(guid, header) }}

                  <!-- {{ guid }} -->
                </v-chip>
              </div>

              <v-switch
                v-else-if="header.type == 'switch'"
                v-model="item[header.value]"
                :readonly="header.readonly"
              >
              </v-switch>
              <span v-else-if="header.type == 'date'">
                {{ item[header.value] | moment(header.format) }}
              </span>
              <span v-else-if="header.type == 'time'">
                {{
                  ("2021-01-01 " + item[header.value]) | moment(header.format)
                }}
              </span>
              <span v-else-if="header.type == 'number'">
                {{ item[header.value] | number(header.format) }}
              </span>
              <span v-else>
                {{ item[header.value] }}
              </span>
            </td>

            <td
              v-if="actionsHeader"
              class=""
              :style="$vuetify.breakpoint.xsOnly ? '' : 'width: 175px'"
              :class="
                $vuetify.breakpoint.xsOnly
                  ? 'v-data-table__mobile-row'
                  : 'text-end'
              "
            >
              <span class="hidden-sm-and-up"> العمليات</span>
              <div>
                <template v-for="(template, j) in actionsHeader.templates">
                  <v-tooltip top :key="j" v-if="template.type == 'btn'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        @click="template.click(item)"
                        v-bind="attrs"
                        v-on="on"
                        :color="template.color"
                      >
                        <v-icon> {{ template.icon }}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ template.text }}</span>
                  </v-tooltip>
                </template>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr
            class="primary--text "
            :class="
              $vuetify.breakpoint.xsOnly
                ? ' v-data-table__mobile-table-row'
                : ''
            "
          >
            <template v-for="(item, i) in footerItems">
              <td v-if="i == 0" :key="i" class="d-none d-sm-flex pa-3">
                {{ $t("total") }}
              </td>
              <td
                v-else-if="!item.showSummary"
                :key="i"
                :class="$vuetify.breakpoint.xsOnly ? 'd-none' : ''"
              ></td>
              <td
                v-else
                :key="i"
                :class="
                  $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
                "
              >
                <span class="hidden-sm-and-up">
                  {{ $t("total") + " " + item.text }}
                </span>
                {{ sumTotals(item.value) | number("0,0") }}
              </td>
            </template>
          </tr>
        </tfoot>
      </template>
    </v-data-table>
    <v-divider />
    <v-row class="mt-2">
      <v-col cols="2" align-self="center">
        <span class="px-5">
          {{ $t("count") }} :
          {{ items && items.length > 0 ? items.length : "" }}
        </span>
      </v-col>

      <v-col align-self="end">
        <v-pagination
          class="mb-2"
          v-model="page"
          :length="pageLength ? pageLength : 0"
        ></v-pagination>
      </v-col>
      <v-col align-self="center" cols="2">
        <!-- <div class="elevation-2 pa-1 rounded"> -->
        <v-select
          dense
          :label="$t('itemPerPage')"
          :items="itemPerPageSelect"
          item-text="text"
          item-value="value"
          v-model="itemsPerPage"
          hide-details=""
        >
        </v-select>
        <!-- </div> -->
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: ["items", "headers", "search", "loading"],

  data() {
    return {
      page: 1,
      itemsPerPage: 5,
      itemPerPageSelect: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "15", value: 15 },
        { text: this.$t("all"), value: -1 },
      ],
    };
  },

  computed: {
    actionsHeader() {
      return this.headers.filter((f) => f.value == "actions")[0];
    },

    footerItems() {
      var list = [];
      for (let i = 0; i < this.headers.length; i++) {
        var header = this.headers[i];

        if (header.type == "number") {
          list.push({
            showSummary: true,
            value: header.value,
            text: header.text,
          });
        } else {
          list.push({ showSummary: false, text: header.text });
        }
      }
      console.log(list);
      return list;
    },

    pageLength() {
      if (this.itemsPerPage == -1) {
        return 1;
      } else {
        try {
          var div = this.items.length / this.itemsPerPage;
          return Number.isInteger(div) ? div : (div | 0) + 1;
        } catch (error) {
          return 1;
        }
      }
    },
  },
  watch: {
    pageLength(val) {
      if (this.page > val) this.page = val;
    },
  },
  methods: {
    getSelectText(guid, header) {
      var text = "";
      try {
        text = header.items.filter((f) => f.value == guid)[0].text;
      } catch (error) {
        text = "";
      }
      console.log(text);
      return text;
    },
    getSelectcolor(guid, header) {
      var text = "";
      try {
        text = header.items.filter((f) => f.value == guid)[0].color;
      } catch (error) {
        text = "";
      }
      return text;
    },
    deleteClick(item) {
      if (this.onDelete) {
        this.onDelete(item);
      }
    },
    sumTotals(value) {
      var total = this.items.reduce((a, b) => a + (b[value] || 0), 0);
      return total;
    },

    rowClick(item) {
      console.log(item);
    },
  },
};
</script>

<style></style>
