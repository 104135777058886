<template>
  <v-container fluid>
    <v-card class="pa-5 mt-3">
      <v-card-title v-if="title">
        {{ title }}
      </v-card-title>
      <!-- <v-row> -->
      <!-- <v-col v-if="title" cols="12" md="12">
          <h2>{{ title }}</h2>
        </v-col> -->
      <!-- <v-col cols="12" md="12"> -->
      <v-toolbar flat>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('search')"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
      </v-toolbar>
      <data-table-component
        :headers="headers"
        :items="requests"
        :search="search"
        :loading="loading"
      />
      <!-- <v-data-table
            :headers="headers"
            :items="requests"
            :search="search"
            :items-per-page="15"
            :loading="loading"
            :loading-text="$t('loading')"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('search')"
                  single-line
                  hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div class="text-end">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      class="mx-1"
                      @click="showReport(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon> mdi-eye </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("Preview") }}</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      class="mx-1"
                      @click="editItem(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon> mdi-pencil </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("edit") }}</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="red darken-2"
                      icon
                      @click="deleteItem(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon> mdi-delete </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("delete") }}</span>
                </v-tooltip>
              </div>
            </template>
          </v-data-table> -->
      <!-- </v-col> -->
      <!-- </v-row> -->
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import DataTableComponent from "../../components/DataTableComponent.vue";

export default {
  components: { DataTableComponent },
  data() {
    return {
      requestData: null,
      title: null,
      requestGuid: this.$route.params.requestGuid,
      loading: true,
      valid: true,
      valid1: true,
      dialog: false,
      dialogDelete: false,
      dialogChangePassword: false,
      editedIndex: -1,
      search: "",

      requests: [],
      reports: [],
      headers: [],
      nodes: [],
      editedItem: {},
      defaultItem: {
        requestGuid: "00000000-0000-0000-0000-000000000000",
        parameterData: "",
      },
      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
      },
    };
  },
  created() {
    this.loading = true;
    this.refreshTable();
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    refreshTable() {
      axios.get("requests/Get?id=" + this.requestGuid).then((response) => {
        this.requestData = response.data.data;
        var parms = JSON.parse(this.requestData.parameterData);

        this.title =
          this.requestData.report.reportCaption +
          " - " +
          this.requestData.node.caption +
          " - " +
          this.$t("fromDate") +
          " (" +
          parms.fromDate +
          ") " +
          this.$t("toDate") +
          " (" +
          parms.toDate +
          ")";
      });

      axios
        .get("requests/ShowRequestData?id=" + this.requestGuid)
        .then((response) => {
          this.headers = response.data.data.JsonHeader;
          this.requests = response.data.data.JsonData;

          //Numbers format
          // for (let i = 0; i < this.requests.length; i++) {
          //   var row = Object.entries(this.requests[i]);
          //   console.log(row);

          //   for (let j = 0; j < row.length; j++) {
          //     if (typeof row[j][1] === "number" && !isNaN(row[j][1])) {
          //       row[j][1] = new Intl.NumberFormat().format(Number(row[j][1]));
          //     }
          //     this.requests[i] = Object.fromEntries(row);
          //   }
          // }
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
  },
};
</script>
